<template>
  <!-- <div id="tree" ref="tree" ></div> -->
  <b-card>
  <b-row>
    <b-col cols="2" md="2">
      <b-button class="ml-2 mb-1" style="width:100%;"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="outline-success"
        @click="collapseAll()"
      >
        Collapse
      </b-button>
    </b-col>
    <b-col cols="2">
      <b-button class="mb-1" style="width:100%;"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="outline-primary"
        @click="expandAll()"
      >
        Expand
      </b-button>
    </b-col>
    <b-col cols="2" md="2" >
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="info"
        class="btn-icon"
        @click="ZoomIn()"
      >
        <feather-icon icon="ZoomInIcon" />
      </b-button>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="info"
        class="ml-1 btn-icon"
        @click="ZoomOut()"
      >
        <feather-icon icon="ZoomOutIcon" />
      </b-button>
      <!-- <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="info"
        class="ml-1 btn-icon"
        @click="chartReference.fit()"
      >
        <feather-icon icon="MaximizeIcon" />
      </b-button> -->
    </b-col>

  </b-row> 
  <div
      class="chart-container pb-1"
      ref="tree"
      col="12"
      style="max-height: 100vh;overflow: auto;"
  ></div>
  </b-card>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BFormInput,
  BLink,
  BMedia,
  BPagination,
  BRow,
  BTable,
  VBTooltip,
  BFormCheckbox,
  BFormCheckboxGroup,
} from "bootstrap-vue";
// import vSelect from "vue-select";
import store from "@/store";
import { onUnmounted, watch, ref } from "@vue/composition-api";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
// import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
// import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import companyTreeStoreModule from "../companyTreeStoreModule";
// import useCompanyTreeList from "./useCompanyTreeList";
// import CompanyTreeListFilters from "./CompanyTreeListFilters.vue";
// import OrgChart from "@balkangraph/orgchart.js";
import Vue from 'vue';
import { OrgChart } from 'd3-org-chart';
import * as d3 from 'd3';
import { cpuUsage } from "process";

export default {
  components: {
    // CompanyTreeListFilters,
     BCard,
     BRow,
     BCol,
    // BFormInput,
     BButton,
    // BTable,
    // BMedia,
    // BLink,
    // BPagination,
    // AppCollapse,
    // AppCollapseItem,
    // vSelect,
    /* eslint-disable */
    ToastificationContent,
    /* eslint-enable */
    // BFormCheckbox,
    // BFormCheckboxGroup,
  },
  directives: {
    Ripple,
  },
  setup() {
    const REFERRAL_APP_STORE_MODULE_NAME = "app-company-trees";
    const isDeleteMultipleCandChecked = ref(false);
    const selectedCompanyTrees = ref([]);
    const companyTreesDeleteCount = ref(0);

    // Register module
    if (!store.hasModule(REFERRAL_APP_STORE_MODULE_NAME)) {
      store.registerModule(
        REFERRAL_APP_STORE_MODULE_NAME,
        companyTreeStoreModule
      );
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(REFERRAL_APP_STORE_MODULE_NAME))
        store.unregisterModule(REFERRAL_APP_STORE_MODULE_NAME);
    });
  },
  data() {
    return {
      nodes: [
        {
          id: 1,
          name: "Denny Curtis",
          title: "CEO",
          img: "https://cdn.balkan.app/shared/2.jpg",
        },
        {
          id: 2,
          pid: 1,
          name: "Ashley Barnett",
          title: "Sales Manager",
          img: "https://cdn.balkan.app/shared/3.jpg",
        },
        {
          id: 3,
          pid: 1,
          name: "Caden Ellison",
          title: "Dev Manager",
          img: "https://cdn.balkan.app/shared/4.jpg",
        },
        {
          id: 4,
          pid: 2,
          name: "Elliot Patel",
          title: "Sales",
          img: "https://cdn.balkan.app/shared/5.jpg",
        },
        {
          id: 5,
          pid: 2,
          name: "Lynn Hussain",
          title: "Sales",
          img: "https://cdn.balkan.app/shared/6.jpg",
        },
        {
          id: 6,
          pid: 3,
          name: "Tanner May",
          title: "Developer",
          img: "https://cdn.balkan.app/shared/7.jpg",
        },
        {
          id: 7,
          pid: 3,
          name: "Fran Parsons",
          title: "Developer",
          img: "https://cdn.balkan.app/shared/8.jpg",
        },
      ],
      treeData: [],
      chartReference: null
    };
  },
  created() {
    // OrgChart.LINK_ROUNDED_CORNERS = 25;
    // OrgChart.templates.diva.link =
    //   '<path stroke-linejoin="round" stroke="#aeaeae" stroke-width="1px" fill="none" d="{rounded}" />';
    // OrgChart.templates.diva.editFormHeaderColor = "#38B22D";
    // OrgChart.scroll.ie = { smooth: 12, speed: 20 };
    // OrgChart.scroll.edge = { smooth: 12, speed: 20 };
    // OrgChart.scroll.chrome = { smooth: 12, speed: 20 };
    // OrgChart.scroll.firefox = { smooth: 12, speed: 20 };
    // OrgChart.scroll.opera = { smooth: 12, speed: 20 };

    this.fetchCompanyTree();
    // if (this.treeData.length > 0) {
    //   // this.mytree(this.$refs.tree, this.treeData);
    //   this.renderChart(this.treeData);
    // }

    // d3.csv(
    //   'https://raw.githubusercontent.com/bumbeishvili/sample-data/main/org.csv'
    // ).then(d => {
    //   console.log('fetched data');
    //   this.treeData = d;
    //   console.log(this.treeData[0]);
    //   this.renderChart(this.treeData);
    // });
  },
  methods: {
    // editRecord(data) {
    //   this.$router
    //     .push(`/edit/company-tree/${data.item.hashid}`)
    //     .catch(() => {});
    // },
    // viewRecord(data) {
    //   this.$router
    //     .push(`/view/company-tree/${data.item.hashid}`)
    //     .catch(() => {});
    // },
    // confirmDeleteRecord(data) {
    //   this.$swal({
    //     title: "Are you sure?",
    //     text: `You want to delete ${data.item.name}.`,
    //     icon: "warning",
    //     sshowCancelButton: true,
    //     confirmButtonText: "Yes, delete it!",
    //     customClass: {
    //       confirmButton: "btn btn-primary",
    //       cancelButton: "btn btn-outline-danger ml-1",
    //     },
    //     buttonsStyling: false,
    //   }).then((result) => {
    //     if (result.value) {
    //       this.deleteRecord(data.item.hashid);
    //     }
    //   });
    // },
    // confirmDeleteMultipleRecords() {
    //   this.$swal({
    //     title: "Are you sure?",
    //     text: `You want to delete ${this.selectedCompanyTrees.length} Company Tree, This cannot be undone.`,
    //     icon: "warning",
    //     showCancelButton: true,
    //     confirmButtonText: "Yes, delete it!",
    //     customClass: {
    //       confirmButton: "btn btn-primary",
    //       cancelButton: "btn btn-outline-danger ml-1",
    //     },
    //     buttonsStyling: false,
    //   }).then((result) => {
    //     if (result.value) {
    //       this.$store
    //         .dispatch(
    //           "app-company-trees/removeMultipleCompanyTrees",
    //           this.selectedCompanyTrees
    //         )
    //         .then((res) => {
    //           this.isDeleteMultipleCandChecked = false;
    //           this.selectedCompanyTrees = [];
    //           if (res.data.success) {
    //             this.refetchData();
    //             this.$toast({
    //               component: ToastificationContent,
    //               props: {
    //                 title: "Deleted Successfully.",
    //                 icon: "BellIcon",
    //                 variant: "success",
    //                 text: res.data.message,
    //               },
    //             });
    //           } else {
    //             this.$toast({
    //               component: ToastificationContent,
    //               props: {
    //                 title: "Company Tree Deletion failed.",
    //                 icon: "BellIcon",
    //                 variant: "danger",
    //                 text: res.data.message,
    //               },
    //             });
    //           }
    //         })
    //         .catch((err) => console.log(err));
    //     }
    //   });
    // },
    // refetchCompanyTreesData() {
    //   console.log("Added Company Tree");
    // },
    // deleteRecord(id) {
    //   const self = this;
    //   this.$store
    //     .dispatch("app-company-trees/removeCompanyTree", id)
    //     .then((res) => {
    //       if (res.status == "204") {
    //         self.$toast({
    //           component: ToastificationContent,
    //           props: {
    //             title: "Company Tree Deleted",
    //             icon: "BellIcon",
    //             variant: "success",
    //             text: "Company Tree Deleted Successfully.",
    //           },
    //         });
    //       } else {
    //         self.$toast({
    //           component: ToastificationContent,
    //           props: {
    //             title: "Company Tree Deletion failed.",
    //             icon: "BellIcon",
    //             variant: "danger",
    //             text: res.data.message,
    //           },
    //         });
    //       }
    //       self.refetchData();
    //     })
    //     .catch((err) => {
    //       self.$toast({
    //         component: ToastificationContent,
    //         props: {
    //           title: "Company Tree Deletion failed.",
    //           icon: "BellIcon",
    //           variant: "danger",
    //         },
    //       });
    //       console.error(err);
    //     });
    // },
    // openWindow(link) {
    //   window.open(link);
    // },

    mytree: function (domEl, x) {
      this.chart = new OrgChart(domEl, {
        template: "diva",
        // linkBinding: {
        //   link_field_0: "Date of Join"
        // },
        nodes: x,
        nodeBinding: {
          field_0: "Name",
          field_1: "Designation",
          img_0: "Profile Picture URL",
        },
        scroll: {
          smooth: 0,
          speed: 0,
        },
        miniMap: true,
        enableSearch: true,
        searchFields: ["Name", "Designation"],
        scaleInitial: 1,
        showXScroll: OrgChart.scroll.visible,
        showYScroll: OrgChart.scroll.visible,
        mouseScrool: OrgChart.action.scroll,
        align: OrgChart.align.center,
        layout: OrgChart.normal,
        enableKeyNavigation: true,
        collapse: {
          level: 3,
          allChildren: true
        },
        editForm: {
          readOnly: true,
          titleBinding: "Name",
          photoBinding: "Profile Picture URL",
          fields:["Name","Bio"],
          buttons: {
            pdf: null,
            remove: null,
            share: null,
            edit: null,
          },
        },
        anim: {func: OrgChart.anim.outBack, duration: 500},
      });
    },
    fetchCompanyTree() {
      this.$store
        .dispatch("app/fetchCompanyTree")
        .then((res) => {
          this.treeData = res.data.data;
          // this.mytree(this.$refs.tree, this.treeData);
         // this.renderChart(this.treeData);
          this.treeData = d3.hierarchy(this.treeData[0])
          .descendants()
          .map(d=>Object.assign(d,{id:d.data.name}))
          .map(d=> Object.assign(d.data,{id:d.id, parentId:d.parent?.id}));
          // .then(d => {
          //   console.log('fetched data');
          //   this.treeData = d;
          //   console.log(this.treeData);
          //   this.renderChart(this.treeData);
          // });
          this.renderChart(this.treeData);
        });
        // .catch((err) => {
        //   this.$toast({
        //     component: ToastificationContent,
        //     props: {
        //       title: "fetching Company Tree failed.",
        //       icon: "BellIcon",
        //       variant: "danger",
        //       text: err.toString(),
        //     },
        //   });
        // });
    },
    expandAll() {
        const { allNodes, root } = this.chartReference.getChartState();
        allNodes.forEach(d => d.data._expanded = true);
        this.chartReference.render();
        return this;
    },
    collapseAll() {
        const { allNodes, root } = this.chartReference.getChartState();
        allNodes.forEach(d => d.data._expanded = false);
        this.chartReference.expandLevel(0);
        this.chartReference.render();
        return this;
    },
    ZoomIn(){
      this.chartReference.zoomIn();
    },
    ZoomOut(){
      this.chartReference.zoomOut();
    },

    renderChart(data) {
      if (!this.chartReference) {
        this.chartReference = new OrgChart();
      }
      this.chartReference
        .container(this.$refs.tree) // node or css selector
        .data(data)
        .rootMargin(100)
          .nodeWidth((d) => 210)
          .nodeHeight((d) => 140)
          .childrenMargin((d) => 150)
          .compactMarginBetween((d) => 75)
          .compactMarginPair((d) => 80)
          .initialZoom(1)
          .linkUpdate(function (d, i, arr) {
            d3.select(this)
              .attr('stroke', (d) =>
                d.data._upToTheRootHighlighted ? '#152785' : '#38B22D'
              )
              .attr('stroke-width', (d) =>
                d.data._upToTheRootHighlighted ? 5 : 1.5
              )
              .attr('stroke-dasharray', '4,4');

            if (d.data._upToTheRootHighlighted) {
              d3.select(this).raise();
            }
          })
          .nodeContent(function (d, i, arr, state) {
            const color = 'black'//#FF4500';
            const imageDim = 80;
            const lightCircleDim = 95;
            const outsideCircleDim = 110;
            return `
                <div style="background-color:#38B22D; position:absolute;width:${
                  d.width
                }px;height:${d.height}px;border-radius: 10px;">
                   <div style="background-color:${color};position:absolute;margin-top:-${outsideCircleDim / 2}px;margin-left:${d.width / 2 - outsideCircleDim / 2}px;border-radius:100px;width:${outsideCircleDim}px;height:${outsideCircleDim}px;"></div>
                   <div style="background-color:#ffffff;position:absolute;margin-top:-${
                     lightCircleDim / 2
                   }px;margin-left:${d.width / 2 - lightCircleDim / 2}px;border-radius:100px;width:${lightCircleDim}px;height:${lightCircleDim}px;"></div>
                   <img src=" ${
                     d.data.imageUrl
                   }" style="position:absolute;margin-top:-${imageDim / 2}px;margin-left:${d.width / 2 - imageDim / 2}px;border-radius:100px;width:${imageDim}px;height:${imageDim}px;" />
                   <div class="card" style="top:${
                     outsideCircleDim / 2 + 10
                   }px;position:absolute;height:30px;width:${d.width}px;background-color:#3AB6E3;">
                      <div style="background-color:${color};height:30px;text-align:center;padding-top:5px;padding-bottom:5px;color:#ffffff;font-weight:bold;font-size:16px">
                          ${d.data.name} 
                      </div>
                      <div style="background-color:#fff5e0;height:30px;text-align:center;padding-top:2px;padding-bottom:2px;color:#424142;font-size:12px">
                          ${d.data.positionName} 
                      </div>
                   </div>
               </div>
  `;
          })
          .render();
      this.chartReference.getChartState().svg.on("wheel.zoom", null);
      //this.chartReference.fit();
    }
  },
  mounted() {},
};
</script>
<style>
[data-ctrl-n-menu-id] circle {
  fill: #38B22D;
}
[data-n-id] rect {
  fill: #38B22D;
}
[data-l-id] path {
  stroke: #000000;
}
</style>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
